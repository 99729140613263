<template>
  <div>
    <p>Content</p>
    <b-row>
      <b-spinner />
    </b-row>
  </div>
</template>

<script>
import { BRow, BSpinner } from "bootstrap-vue";
import authService from "@/services/authService";

export default {
  components: {
    BRow,
    BSpinner,
  },
  created() {
    authService
      .getOauthAuthorize(this.$route.query)
      .then(response => {
        if (response?.data?.code && response?.data.state) {
          window.location.href = `${this.$route.query.redirect_uri}?code=${response.data.code}&state=${response.data.state}`;
        }
      })
      .catch(e => {
        console.log(e);
        this.$toast(makeErrorToast("Something went wrong!"));
        this.$log.error(e);
      });
  },
};
</script>
